<template>
  <div class="main-content white">
    <div class="edit-msg">
      <textarea
        placeholder="請輸入流團通知訊息"
        v-model="notification"
      ></textarea>
    </div>
  </div>
</template>

<script>
import memberSettingNotificationMixin from '@/mixins/member-setting-notifications';
export default {
  mixins: [memberSettingNotificationMixin],
  data: function() {
    return {
      key: 'default_canceled_notification', //流團
      default: '您的跟團已到截止時間，請點擊至網站查看活動資訊'
    };
  }
};
</script>
